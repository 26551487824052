// import { useMemo } from 'react';
import { ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import svLocale from 'date-fns/locale/sv';
import DateAdapter from '@mui/lab/AdapterDateFns';

import { useSwitchableTheme, DarkContext } from './state/dark';
import { GraphPage } from './ui/GraphPage';
import { getThemeConfig } from './appConfig';

export const App = () => {
  const { theme, darkCtx } = useSwitchableTheme(getThemeConfig);
  return (
    <DarkContext.Provider value={darkCtx}>
      <GlobalStyles
        styles={{
          body: { overflow: 'hidden' },
          '::-webkit-scrollbar ': {
            width: 5,
          },
          '::-webkit-scrollbar-track ': {
            background: theme.palette.background.paper,
          },
          '::-webkit-scrollbar-thumb ': {
            background: theme.palette.action.disabled,
            borderRadius: 2.5,
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={DateAdapter} locale={svLocale}>
          <GraphPage />
        </LocalizationProvider>
      </ThemeProvider>
    </DarkContext.Provider>
  );
};
