import { createMachine, assign, spawn, send, actions } from 'xstate';
import * as R from 'ramda';

import { generateString, OBJ, ARR, adjustCollectionItem } from '../utils/dataUtils';
import { sleep } from '../utils/ctrl';
import { appUrl, jsToUri, fromParams, uriToJs } from '../utils/url';
import { channelMachine, channelMachineContext } from './channelMachine';
import { dispatch, getState } from './appState';

const { pure } = actions;

export const graphPageMachineContext = {
  drawerOpen: true,
  plotCount: 1,
  range: { from: undefined, to: undefined },
  channels: [],
};

export const graphPageMachineConf = {
  id: 'graphPage',
  initial: 'initialize',
  context: graphPageMachineContext,
  states: {
    initialize: {
      invoke: {
        src: 'loadFromParams',
        onDone: { actions: ['initChannels', 'initRest'], target: 'idle' },
        onError: { target: 'idle' },
      },
    },
    idle: {
      on: {
        DRAWER: { actions: 'setDrawer' },
        FROM: { actions: 'setFrom' },
        TO: { actions: 'setTo' },
        SET_SERIES: { actions: 'setSeries' },
        ALIAS: { actions: 'setAlias' },
        UPDATE_SERIE: { actions: 'updateSerie' },
        ACTIVATE_CHANNEL: { actions: 'activateChannel' },
        INC_PLOTS: { actions: 'incPlots' },
        DEC_PLOTS: { actions: ['decPlots', 'clampSeries'] },
        REFETCH: { actions: 'sendRefetch' },
        ADD: { actions: 'addChannel' },
        REMOVE: { actions: 'removeChannel' },
        SAVE: { actions: 'saveContext', target: 'saving' },
      },
    },
    saving: {
      invoke: {
        src: 'saver',
        onDone: { target: 'idle' },
        onError: { target: 'idle' },
      },
    },
  },
};

const saveChecker = (count) =>
  sleep(50).then(({ channels: { length } } = getState()) => length < count && saveChecker(count));

export const graphPageMachine = createMachine(graphPageMachineConf, {
  services: {
    loadFromParams: () => {
      const stateParam = fromParams('state');
      if (!stateParam) return Promise.reject(Error('Just take the onError path'));
      return Promise.resolve(uriToJs(stateParam));
    },
    saver: ({ channels: { length } }) =>
      saveChecker(length).then(
        () => (document.location = appUrl('', { state: jsToUri(getState()) })),
      ),
  },
  actions: {
    setDrawer: assign({
      drawerOpen: ({ drawerOpen }, { value }) => (R.isNil(value) ? !drawerOpen : value),
    }),
    setFrom: assign({ range: ({ range }, { date }) => ({ ...range, from: date }) }),
    setTo: assign({ range: ({ range }, { date }) => ({ ...range, to: date }) }),
    initChannels: assign({
      channels: (ctx, { data: { channels: incommingChannels } }) => {
        const result = R.map((ch) => {
          const { id: ctxId, series: [{ dataId = '', sourceId = '' } = OBJ] = ARR } = ch;
          const id = ctxId ?? generateString(8);
          return {
            alias: '',
            active: false,
            ...ch,
            ref: spawn(
              channelMachine.withContext({ ...channelMachineContext, dataId, sourceId, id }),
              id,
            ),
            id,
          };
        })(incommingChannels);
        return result;
      },
    }),
    initRest: assign({
      drawerOpen: (ctx, { data: { drawerOpen = true } }) => drawerOpen,
      plotCount: (ctx, { data: { plotCount = 1 } }) => plotCount,
      range: (ctx, { data: { range = { from: undefined, to: undefined } } }) => range,
    }),
    addChannel: assign({
      channels: ({ channels }, { id: customId, ctx = OBJ }) => {
        const { id: ctxId, dataId = '', sourceId = '' } = ctx;
        const id = ctxId ?? customId ?? generateString(8);
        return R.append({
          alias: '',
          active: false,
          ...ctx,
          ref: spawn(
            channelMachine.withContext({ ...channelMachineContext, dataId, sourceId, id }),
            id,
          ),
          id,
          series: [], // [{ id, sourceId, dataId, path: '', plot: 0, alias: '', serie: [] }]
        })(channels);
      },
    }),
    removeChannel: assign({
      channels: ({ channels }, { id }) => R.reject(R.propEq('id', id))(channels),
    }),
    updateSerie: assign({
      channels: ({ channels }, { id, path, plot, alias }) => {
        return adjustCollectionItem()(
          id,
          R.evolve({
            series: adjustCollectionItem('path')(
              path,
              R.evolve({ alias: R.defaultTo(R.__, alias), plot: R.defaultTo(R.__, plot) }),
            ),
          }),
        )(channels);
      },
    }),
    setAlias: assign({
      channels: ({ channels }, { id, alias }) =>
        adjustCollectionItem()(id, R.assoc('alias', alias))(channels),
    }),
    setSeries: assign({
      channels: ({ channels }, { series, id, dataId, sourceId }) => {
        const res = adjustCollectionItem()(
          id,
          R.evolve({
            series: (prevSeries) =>
              R.addIndex(R.map)((serie, ix) => {
                const { alias, plot = 0 } = R.propOr(OBJ, ix, prevSeries);
                return { ...serie, alias: alias || serie.path, plot };
              })(series),
            active: R.T,
            alias: (ali) => ali || `${sourceId}.${dataId}`,
          }),
        )(channels);
        return res;
      },
    }),
    activateChannel: assign({
      channels: ({ channels }, { id, active }) =>
        adjustCollectionItem()(id, R.assoc('active', active))(channels),
    }),
    incPlots: assign({ plotCount: ({ plotCount }) => plotCount + 1 }),
    sendRefetch: pure(({ channels, range }) =>
      R.map(({ ref }) => send({ type: 'FETCH', params: range }, { to: ref }))(channels),
    ),
    decPlots: assign({
      plotCount: ({ plotCount }) => (plotCount < 2 ? plotCount : plotCount - 1),
    }),
    clampSeries: assign({
      channels: ({ channels, plotCount }) =>
        R.map(
          R.evolve({
            series: R.map((item) => (item.plot > plotCount ? { ...item, plot: 0 } : item)),
          }),
        )(channels),
    }),
    saveContext: (ctx) => {
      const cleanCtx = R.evolve({
        channels: R.map(
          R.pipe(R.evolve({ series: R.map(R.dissoc('serie')) }), R.omit(['id', 'ref'])),
        ),
      })(ctx);
      dispatch({ type: 'init', ctx: cleanCtx });
    },
  },
});

/*

TODO:
Visualisera om möjligt färger som vertical borders till vänster för dataset och -serier.
Går inte att använda refetch all efter man fått tomt svar.
  Återskapa:
  * Hämta ett dataset
  * Byt tidsperiod till ett då det inte finns data (empty result kommer)
  * Försök använda Refetch all (går inte)
  MINOR på den.
  Går lätt att gå runt.

- filename for save as image: date range
- Add graph tool controls
  - Top padding
- 2 lines x-axis with date?
- add sampleInterval
- add padding field: none, null, linear
- json export
// Spara "templates" för uppsättningen av hämtade dataset och grafinställningar.
// Fokus på grafval när man har gjort en lyckad hämtning.
// Fokus på Source när man just tryckt +. Tangentbordsvänligare!
// Möjlighet att visa olika set från samma datakanal i data explorer i varsin figur.
//   Exempelvis:
//   Första figuren visar temperatur i Jeppes källare vecka 30 år 2020
//   Figuren under visar temperatur i Jeppes källare vecka 30 år 2021
//
// - cancel refetch
// - remove plot/graph when edit or fetch result arrives
// - Lock sourceId and dataId after first fetch, only limit on edit/refetch
//   - Move down plot selector to same for single and multi
// - Firefox default scrollbars also in Chrome
// - responsiveness narrowing
// - Zoom bug. Zooms only plot 1 and 2
// - split DataForm
// - reversed minimap
// - graph title
// - plot strings as numbers. No, JSON parsed!
// - hotkey for drawer
// - remove body scroll!
// - from/to is broken?
// - remove channel should remove from graph
*/
