import { useMemo } from 'react';
import * as R from 'ramda';
import { styled } from '@mui/material/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';

import { echarts } from '../utils/echarts';

const colors = [
  'rgb(50, 210, 10)',
  'rgb(150,83,197)',
  'rgb(255,150,0)',
  'rgb(220,80,20)',
  '#32C5E9',
  '#FFDB5C',
  '#fb7293',
  '#9d96f5',
  '#c23531',
  '#dd6b66',
  '#9FE6B8',
  '#ca8622',
  '#e7bcf3',
  '#61a0a8',
  '#37A2DA',
  '#8378EA',
  '#d48265',
  '#E062AE',
  '#67E0E3',
  '#ff9f7f',
  '#c4ccd3',
  '#e69d87',
  '#8dc1a9',
  '#ea7e53',
  '#eedd78',
  '#73a373',
  '#73b9bc',
  '#91c7ae',
  '#2f4554',
  '#74af83',
  '#E690D1',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#7289ab',
  '#91ca8c',
  '#f49f42',
  '#96BFFF',
];

const serieDefaults = () => ({
  // name: 'SerieA',
  type: 'line',
  // smooth: true,
  // symbol: 'none',
  sampling: 'lttb',
  symbolSize: 6,
  // itemStyle: {
  //   color: colors[idx],
  //   // width: 1,
  // },
  // areaStyle: {},
  large: true, // TODO: make dynamic?
  // data: randData,

  // FOR MULTIPLE PLOTS: (handled from caller)
  // xAxisIndex: 1,
  // yAxisIndex: 1,
});

const getTitleConf = (title) => ({
  title: {
    left: 'left',
    text: title,
  },
});

const getBaseOptions = (imgName) => ({
  // Global palette:
  color: colors,
  tooltip: {
    trigger: 'axis',
    // position: (pt) => [pt[0], '10%'],
    axisPointer: {
      type: 'line',
      // animation: false,
      label: {
        show: true,
      },
    },
    // renderMode: 'richText',
    // transitionDuration: 0,
  },
  legend: {},
  title: {},
  toolbox: {
    orient: 'vertical',
    right: 10,
    top: 20,
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
      },
      dataView: { readOnly: true },
      restore: {},
      saveAsImage: {
        name: imgName,
      },
      // saveAsJson: {},
    },
  },
  // dataZoom: {
  //   show: true,
  //   realtime: true,
  //   start: 0,
  //   end: 100,
  // },
  // dataZoom: [
  //   {
  //     show: true,
  //     realtime: true,
  //     start: 0,
  //     end: 100,
  //     xAxisIndex: [0, 1],
  //   },
  //   {
  //     type: 'inside',
  //     realtime: true,
  //     start: 0,
  //     end: 100,
  //     xAxisIndex: [0, 1],
  //   },
  // ],
  axisPointer: {
    link: { xAxisIndex: 'all' },
  },
  series: [],
});

const generateOptions = (plotCount, series) => {
  const height = 92 / plotCount - 2;
  const plots = R.range(0, plotCount);
  const imgName = series?.[0]?.data?.[0]?.[0]?.toISOString();
  return {
    ...getBaseOptions(imgName),
    grid: R.map((idx) => ({
      left: 80,
      right: 50,
      height: `${height}%`,
      top: `${3 + (height + 2) * idx}%`,
    }))(plots),
    // grid: [
    //   {
    //     left: 50,
    //     right: 50,
    //     height: '35%',
    //   },
    //   {
    //     left: 50,
    //     right: 50,
    //     top: '55%',
    //     height: '35%',
    //   },
    // ],
    xAxis: R.map((gridIndex) => ({
      // type: 'category',
      type: 'time',
      scale: true,
      boundaryGap: false,
      gridIndex,
      // splitNumber: 20,
      // inverse: true,
      // axisLine: { onZero: false },
      // splitLine: { show: false },
      // min: 'dataMin',
      // max: 'dataMax',
    }))(plots),
    // xAxis: [
    //   {
    //     type: 'time',
    //     boundaryGap: false,
    //     // splitLine: {
    //     //   show: false,
    //     // },
    //     // splitArea: {
    //     //   show: false,
    //     // },
    //     // axisLine: { onZero: true },
    //   },
    //   {
    //     gridIndex: 1,
    //     type: 'time',
    //     boundaryGap: false,
    //     axisLine: { onZero: true },
    //     // position: 'top',
    //   },
    // ],
    yAxis: R.map((gridIndex) => ({
      type: 'value',
      scale: true,
      gridIndex,
    }))(plots),
    // yAxis: [
    //   {
    //     type: 'value',
    //     // boundaryGap: [0, '100%'],
    //   },
    //   {
    //     gridIndex: 1,
    //     type: 'value',
    //     // boundaryGap: [0, '100%'],
    //   },
    // ],
    dataZoom: R.map(() => ({
      show: true,
      realtime: true,
      start: 0,
      end: 100,
      xAxisIndex: plots,
    }))(plots),
  };
};

const ReactEChart = styled(ReactEChartsCore)({
  flexGrow: 1,
});

export const MultiGraph = ({ series: seriesData, title, plotCount }) => {
  const series = useMemo(
    () => R.addIndex(R.map)((serie, idx) => ({ ...serieDefaults(idx), ...serie }))(seriesData),
    [seriesData],
  );
  const option = useMemo(
    () => ({
      ...generateOptions(plotCount, series),
      series,
      ...(title ? getTitleConf(title) : {}),
    }),
    [plotCount, title, series],
  );
  return (
    <ReactEChart
      echarts={echarts}
      option={option}
      notMerge
      // lazyUpdate
      theme="dark"
      // mask="__-__-____ __:__"
      // onChartReady={Function.prototype}
      // onEvents={EventsDict}
      // opts={option}
    />
  );
};
