import { createMachine, assign, sendParent } from 'xstate';
import * as R from 'ramda';
import { path as pathRx } from 'rambdax';
import { flattenObject } from 'flatten-anything';

import { fetchData } from './api';
import { maybeUnjson, OBJ } from '../utils/dataUtils';
import { defaultLimit } from '../appConfig';

const dataToSerie = (path) =>
  R.map(({ timestamp, data: y } = {}) => {
    return [new Date(timestamp), path ? pathRx(path)(y ?? {}) : y];
  });

const resultEventToPreparedSeries = ({ sourceId, dataId, points }) =>
  R.pipe(
    pathRx('data.data.0.data'),
    maybeUnjson,
    flattenObject,
    R.keys,
    R.when(R.isEmpty, () => ['']),
    R.map((path) => ({
      sourceId,
      dataId,
      path,
      serie: dataToSerie(path)(points),
    })),
  );

export const channelMachineContext = {
  // id: '',
  sourceId: '',
  dataId: '',
  limit: defaultLimit,
  error: undefined,
  count: 0,
};

const channelMachineConf = {
  id: 'channel',
  initial: 'init',
  context: channelMachineContext,
  states: {
    init: {
      always: [{ cond: 'isPreset', target: 'refetching' }, { target: 'start' }],
    },
    start: {
      on: { FETCH: { target: 'fetching' } },
    },
    refetch: {
      on: {
        FETCH: { target: 'refetching' },
        CANCEL: { actions: ['sendActivate'], target: 'done' },
      },
    },
    fetching: {
      entry: ['clearError', 'saveParams'],
      invoke: {
        src: 'fetcher',
        onDone: {
          actions: ['saveCount', 'sendSeries'],
          target: 'done',
        },
        onError: { actions: 'setError', target: 'start' },
      },
    },
    refetching: {
      entry: ['clearError', 'saveParams'],
      invoke: {
        src: 'fetcher',
        onDone: {
          actions: ['saveCount', 'sendSeries'],
          target: 'done',
        },
        onError: { actions: 'setError', target: 'refetch' },
      },
    },
    done: {
      on: {
        EDIT: { actions: 'sendActivate', target: 'refetch' },
        FETCH: 'refetching',
      },
    },
  },
};

export const channelMachine = createMachine(channelMachineConf, {
  services: {
    fetcher: ({ sourceId, dataId, limit }, { params }) =>
      fetchData({ sourceId, dataId, limit, ...params }),
  },
  guards: {
    isPreset: ({ sourceId, dataId }) => sourceId && dataId,
  },
  actions: {
    sendActivate: sendParent(({ id }, { active = true }) => ({
      type: 'ACTIVATE_CHANNEL',
      id,
      active,
    })),
    saveParams: assign({
      sourceId: ({ sourceId: prev }, { params: { sourceId = prev } = OBJ }) => {
        return sourceId;
      },
      dataId: ({ dataId: prev }, { params: { dataId = prev } = OBJ }) => dataId,
      limit: (ctx, { params: { limit } = OBJ }) => limit,
    }),
    saveCount: assign({
      count: (ctx, { data: { count } }) => count,
    }),
    sendSeries: sendParent(({ sourceId, dataId, id }, ev) => {
      const {
        data: { data: points },
      } = ev;
      return {
        type: 'SET_SERIES',
        sourceId,
        dataId,
        series: resultEventToPreparedSeries({ sourceId, dataId, points })(ev),
        id,
      };
    }),
    setError: assign({ error: (ctx, { data }) => data }),
    clearError: assign({ error: () => undefined }),
  },
});
