import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import RemoveIcon from '@mui/icons-material/Remove';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Divider,
  Tooltip,
  Toolbar,
  Drawer as MuiDrawer,
  Fab,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useKeypress from 'react-use-keypress';

import { RangeForm } from './RangeForm';
import { DataForm } from './DataForm';
import { drawerBodyWidth } from '../appConfig';

const openedMixin = (theme) => ({
  width: drawerBodyWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerBodyWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const AppDrawer = ({ handleResize, drawerOpen, plotCount, from, to, channels, send }) => {
  const handleDrawerOpen = () => {
    send({ type: 'DRAWER', value: true });
    handleResize();
  };
  const handleDrawerClose = () => send({ type: 'DRAWER', value: false });

  useKeypress('§', () => void (drawerOpen ? handleDrawerClose : handleDrawerOpen)());
  useKeypress('F4', () => void send('REFETCH'));

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
        <Box px={1} pt={1}>
          <MultilineChartIcon fontSize="large" color="primary" />
        </Box>
        <Tooltip title="Remove bottom chart">
          <IconButton aria-label="remove" onClick={() => send('DEC_PLOTS')}>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Append new chart">
          <IconButton aria-label="add" onClick={() => send('INC_PLOTS')}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Refetch all [F4]">
          <IconButton
            aria-label="refetch all"
            onClick={() => send('REFETCH')}
            size={drawerOpen ? undefined : 'small'}
          >
            <SyncIcon />
          </IconButton>
        </Tooltip>
        <Box flexGrow={1} />
        <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}>
          {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <RangeForm from={from} to={to} send={send} narrow={!drawerOpen} />
      <Divider />
      {channels.map(({ id, ref, series, alias }) => (
        <DataForm
          key={id}
          id={id}
          service={ref}
          alias={alias}
          sendParent={send}
          single={channels.length <= 2}
          plotCount={plotCount}
          from={from}
          to={to}
          narrow={!drawerOpen}
          series={series}
        />
      ))}
      <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
        <Fab size="small" color="primary" aria-label="add" onClick={() => send('ADD')}>
          <AddIcon />
        </Fab>
      </Toolbar>
      <Box flexGrow={1} />
      <Toolbar>
        <Tooltip title="Save setup as URL">
          <IconButton aria-label="remove" onClick={() => send('SAVE')}>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </Drawer>
  );
};
