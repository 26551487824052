import {
  createContext,
  // useEffect, useRef,
  useMemo,
} from 'react';
import {
  // useMediaQuery,
  createTheme,
} from '@mui/material';
// import createPersistedState from 'use-persisted-state';

// const useStoredDark = createPersistedState('darkmode');
export const DarkContext = createContext();

// export const useDarkState = () => {
//   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
//   const [isDark, setDark] = useStoredDark();
//   /* eslint-disable no-null/no-null */
//   const wasUnset = useRef(isDark == null);
//   /* eslint-enable no-null/no-null */
//
//   useEffect(() => {
//     if (wasUnset.current) setDark(prefersDarkMode);
//   }, [prefersDarkMode, setDark]);
//
//   return { isDark, setDark };
// };

export const useSwitchableTheme = (getThemeConfig) => {
  const isDark = true;

  const theme = useMemo(() => {
    const { palette = {}, ...rest } = getThemeConfig(isDark);
    const themeConf = {
      ...rest,
      palette: {
        mode: isDark ? 'dark' : 'light',
        ...palette,
      },
    };
    const newTheme = createTheme(themeConf);
    window.THEME = newTheme;
    return newTheme;
  }, [isDark, getThemeConfig]);

  return useMemo(
    () => ({
      theme,
      darkCtx: {
        isDark,
      },
    }),
    [theme, isDark],
  );
};
