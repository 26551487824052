// export const inputWidth = 220;
export const drawerBodyWidth = 380;
export const defaultLimit = 1000;

export const getThemeConfig = () => ({
  palette: {
    primary: { main: '#8bc34a' },
    background: { paper: '#201C3A', default: '#100C2A' },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       color: 'inherit',
    //     },
    //   },
    // },
  },
});
