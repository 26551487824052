import { DateTimePicker as MuiDateTimePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { addHours } from 'date-fns/fp';

import { OBJ } from '../utils/dataUtils';

const DateTimePicker = styled(MuiDateTimePicker)({});

const NarrowingPicker = ({ narrow, label, ...rest }) => {
  const now = addHours(1)(new Date());

  return (
    <DateTimePicker
      {...rest}
      label={!narrow ? label : undefined}
      renderInput={(props) => <TextField fullWidth {...props} />}
      ampm={false}
      clearable
      showTodayButton
      OpenPickerButtonProps={narrow ? { sx: { right: '22px' } } : OBJ}
      maxDateTime={now}
    />
  );
};

export const RangeForm = ({ from, to, send, narrow }) => (
  <Box p={2} display="flex" flexDirection="column">
    <NarrowingPicker
      narrow={narrow}
      label="From"
      /* eslint-disable no-null/no-null */
      value={from ?? null}
      /* eslint-enable no-null/no-null */
      onChange={(date) => send({ type: 'FROM', date })}
    />
    <Box p={1} />
    <NarrowingPicker
      narrow={narrow}
      label="To"
      /* eslint-disable no-null/no-null */
      value={to ?? null}
      /* eslint-enable no-null/no-null */
      onChange={(date) => send({ type: 'TO', date })}
      minDateTime={from}
    />
  </Box>
);
