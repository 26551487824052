import { useEffect } from 'react';
import { bi } from 'tri-fp';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  Toolbar,
  TextField as MuiTextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { defaultLimit } from '../appConfig';

const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const ChannelBar = ({ narrow, firstfetch, sendParent, sourceId, dataId, id, single }) => (
  <Toolbar
    variant="dense"
    disableGutters
    sx={{ p: 1, justifyContent: 'flex-end', bgcolor: 'background.default' }}
  >
    {!narrow && (
      <Box flexGrow={1}>
        <Typography component="span">{firstfetch ? 'Channel' : `${sourceId}.${dataId}`}</Typography>
      </Box>
    )}
    <IconButton
      aria-label="delete"
      onClick={() => sendParent({ type: 'REMOVE', id, sourceId, dataId })}
      disabled={single}
    >
      <DeleteIcon />
    </IconButton>
  </Toolbar>
);

const ErrorMsg = ({ narrow, error }) =>
  Boolean(error) &&
  (narrow ? (
    <Tooltip title={error.message}>
      <WarningIcon color="error" style={{ marginLeft: 12 }} />
    </Tooltip>
  ) : (
    <Typography color="error">{error.message}</Typography>
  ));

export const DataFetchForm = ({
  narrow,
  sendParent,
  matches,
  id,
  from,
  to,
  sourceId,
  dataId,
  limit,
  single,
  error,
  send,
}) => {
  const firstfetch = ['start', 'fetching'].some(matches);
  const fetching = ['fetching', 'refetching'].some(matches);
  const { register, handleSubmit, setFocus } = useForm();

  useEffect(() => void bi(setFocus)('sourceId'), [setFocus]);

  const onSubmit = (params) => send({ type: 'FETCH', params: { ...params, from, to } });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ChannelBar
        narrow={narrow}
        firstfetch={firstfetch}
        sendParent={sendParent}
        sourceId={sourceId}
        dataId={dataId}
        id={id}
        single={single}
      />
      <Box display="flex" flexDirection="column" px={narrow ? 0.5 : 2} pt={1}>
        {firstfetch && (
          <TextField
            required
            id="sourceId"
            label="Source ID"
            defaultValue={sourceId ?? ''}
            inputProps={register('sourceId')}
            fullWidth
          />
        )}
        {firstfetch && (
          <TextField
            required
            id="dataId"
            label="Data ID"
            defaultValue={dataId ?? ''}
            inputProps={register('dataId')}
            fullWidth
          />
        )}
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {!narrow && (
            <TextField
              id="limit"
              label="Limit"
              type="number"
              defaultValue={limit ?? defaultLimit}
              inputProps={register('limit')}
              fullWidth
            />
          )}
          <Box p={1} /* spacer */ />
          <Box display="flex" pb={1}>
            {fetching ? (
              <CircularProgress />
            ) : (
              <>
                <IconButton
                  aria-label="cancel"
                  onClick={() => send('CANCEL')}
                  sx={{ display: firstfetch ? 'none' : 'inline-flex' }}
                >
                  <CancelIcon />
                </IconButton>
                <IconButton aria-label="fetch" type="submit" disabled={fetching}>
                  <CloudDownloadIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
        <ErrorMsg narrow={narrow} error={error} />
      </Box>
    </form>
  );
};
