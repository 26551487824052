import * as R from 'ramda';
import {
  Divider,
  IconButton,
  Chip,
  Select,
  List,
  ListItem,
  Collapse,
  MenuItem,
  Tooltip,
  Toolbar,
  FormControl,
  TextField as MuiTextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useActor } from '@xstate/react';
import { useForm } from 'react-hook-form';

import { DataFetchForm } from './DataFetchForm';

const Form = styled('form')({ width: '100%' });
const HiddenButton = styled('button')({ display: 'none' });

const PathList = styled(List)(() => ({
  maxHeight: 240,
  overflowY: 'auto',
}));

const SelectDense = styled(Select)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1, 1, 0.5, 1),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const NarrowingListItem = styled(ListItem)(({ theme, narrow }) => ({
  padding: theme.spacing(0.5, narrow ? 0 : 2, 0.5, narrow ? 0.5 : 1),
  '& .MuiSelect-select': {
    padding: theme.spacing(1, 0, 0.5, 1.5),
  },
}));

const createPlotOptions = R.pipe(
  R.add(1),
  R.range(0),
  R.map((x) => (
    <MenuItem key={x} value={x}>
      {x}
    </MenuItem>
  )),
);

export const PathItem = ({ path, alias, plot, id, sendParent, plotOptions, narrow, idx }) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendParent({ type: 'UPDATE_SERIE', id, path, ...data });
    return false;
  };

  const plotSelect = (
    <FormControl>
      <SelectDense
        value={plot}
        onChange={(ev) => sendParent({ type: 'UPDATE_SERIE', id, path, plot: ev.target.value })}
        inputProps={{ autoFocus: idx === 0 }}
      >
        {plotOptions}
      </SelectDense>
    </FormControl>
  );

  return (
    <>
      <NarrowingListItem disablePadding narrow={narrow}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {!narrow && (
            <TextField
              id="alias"
              defaultValue={alias || path || 'Root data'}
              fullWidth
              inputProps={register('alias')}
            />
          )}
          <HiddenButton type="submit">Submit</HiddenButton>
        </Form>
        {narrow ? <Tooltip title={path}>{plotSelect}</Tooltip> : plotSelect}
      </NarrowingListItem>
      <Divider />
    </>
  );
};

export const DataForm = ({
  id,
  service,
  sendParent,
  single,
  plotCount,
  from,
  to,
  narrow,
  series,
  alias,
}) => {
  const [
    {
      matches,
      context: { error, count, sourceId, dataId, limit },
    },
    send,
  ] = useActor(service);

  const inGraphState = matches('done');
  const plotOptions = createPlotOptions(plotCount);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    sendParent({ type: 'ALIAS', id, ...data });
    return false;
  };

  return (
    <>
      <Collapse in={!inGraphState}>
        <DataFetchForm
          narrow={narrow}
          sendParent={sendParent}
          from={from}
          to={to}
          single={single}
          id={id}
          sourceId={sourceId}
          dataId={dataId}
          limit={limit}
          error={error}
          matches={matches}
          send={send}
        />
      </Collapse>
      <Collapse in={inGraphState}>
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ p: 1, justifyContent: 'flex-end', bgcolor: 'background.default' }}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            {!narrow && Boolean(series.length) && (
              <TextField id="alias" fullWidth defaultValue={alias} inputProps={register('alias')} />
            )}
          </Form>
          <Chip variant="outlined" size="small" label={count} />
          <IconButton
            aria-label="edit"
            onClick={() => send({ type: 'EDIT', active: false })}
            disabled={!inGraphState}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => sendParent({ type: 'REMOVE', id })}
            disabled={single}
          >
            <DeleteIcon />
          </IconButton>
        </Toolbar>
        <PathList dense>
          {series.map(({ plot, path, alias: sAlias }, idx) => (
            <PathItem
              key={path}
              plot={plot}
              path={path}
              alias={sAlias}
              id={id}
              sendParent={sendParent}
              plotOptions={plotOptions}
              narrow={narrow}
              idx={idx}
            />
          ))}
        </PathList>
      </Collapse>
      <Divider />
    </>
  );
};
