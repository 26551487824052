import { useState, useMemo, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { debounce, delay } from 'rambdax';
import { Box, Dialog, CircularProgress } from '@mui/material';
import { useMachine } from '@xstate/react';

import { AppDrawer } from './AppDrawer';
import { MultiGraph } from './MultiGraph';
import { graphPageMachine } from '../state/machine';

const useGraphFlexDirFix = () => {
  const [graphFlexDir, setGraphFlexDir] = useState('column'); // Ugly fix of layout responsiveness
  const handleResize = useCallback(
    debounce(() => {
      setGraphFlexDir('row');
      delay(10).then(() => setGraphFlexDir('column'));
    }, 150),
    [setGraphFlexDir],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);
  return { graphFlexDir, handleResize };
};

export const GraphPage = () => {
  const { graphFlexDir, handleResize } = useGraphFlexDirFix();

  const [
    {
      context: { drawerOpen, plotCount, range: { from, to } = {}, channels },
      matches,
    },
    send,
  ] = useMachine(() => graphPageMachine);

  const series = useMemo(
    () =>
      R.reduce(
        (acc, { active, alias: chAlias, series: ss }) =>
          active
            ? [
                ...acc,
                ...R.reduce(
                  (accc, { plot, serie, alias }) =>
                    plot > 0
                      ? [
                          ...accc,
                          {
                            title: '',
                            name: `${chAlias}${alias ? `:${alias}` : ''}`,
                            data: serie,
                            xAxisIndex: plot - 1,
                            yAxisIndex: plot - 1,
                          },
                        ]
                      : accc,
                  [],
                )(ss),
              ]
            : acc,
        [],
      )(channels),
    [channels],
  );

  return (
    <Box display="flex" flexDirection="row" height="100vh" width="100vw" alignItems="stretch">
      <AppDrawer
        handleResize={handleResize}
        drawerOpen={drawerOpen}
        plotCount={plotCount}
        from={from}
        to={to}
        channels={channels}
        send={send}
      />
      <Box flexGrow={1} display="flex" flexDirection={graphFlexDir}>
        <MultiGraph series={series} plotCount={plotCount} />
      </Box>
      <Dialog open={!matches('idle')}>
        <Box p={4}>
          <CircularProgress disableShrink />
        </Box>
      </Dialog>
    </Box>
  );
};
